import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProjectBox from "components/ProjectBox/ProjectBox";
import FullButton from "components/Buttons/FullButton";
import { getProjects } from "store/actions/cmsActions";
import { FaFolderOpen } from "react-icons/fa";

const ProjectsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  useEffect(() => {
    dispatch(getProjects(organizationId));
  }, [dispatch, organizationId]);

  // Get only the 3 latest projects
  const latestProjects = projects?.slice(0, 3);

  const handleViewAll = () => {
    navigate("/projects");
  };

  const projectList = projects?.length > 0 ? (
    <ProjectGrid>
      {latestProjects.map((project, i) => (
        <ProjectContainer key={i}>
          <ProjectBox
            img={project.imageUrl}
            title={project.title}
            description={project.description}
            tag="Community"
            author="GPTF"
          />
        </ProjectContainer>
      ))}
    </ProjectGrid>
  ) : (
    <NoContentMessage>
      <FaFolderOpen size={50} />
      <h2>No Projects Available</h2>
      <p>There are currently no projects available. Please check back later.</p>
    </NoContentMessage>
  );

  return (
    <Wrapper id="projects">
      <SectionBackground>
        <Container>
          <HeaderInfo>
            <Title>Our Projects</Title>
            <Description>
              Explore our range of conservation and community development projects across Namibia.
              These initiatives showcase our commitment to wildlife protection and sustainable resource management.
            </Description>
          </HeaderInfo>
          {projectList}
          {projects?.length > 0 && (
            <ButtonWrapper>
              <FullButton title="View All Projects" action={handleViewAll} />
            </ButtonWrapper>
          )}
        </Container>
      </SectionBackground>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: #f8f8f8;
`;

const SectionBackground = styled.div`
  width: 100%;
  background: white;
  padding: 40px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const HeaderInfo = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 0;
`;

const ProjectGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start;
  margin-bottom: 50px;
  
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const ProjectContainer = styled.div`
  flex: 0 0 calc(33.333% - 20px);
  min-width: 280px;
  max-width: calc(33.333% - 20px);
  
  @media (max-width: 992px) {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ProjectWrapper = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid #eee;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* This centers the content vertically */
  justify-content: center;
  margin-top: 40px;
  width: fit-content;
`;


const NoContentMessage = styled.div`
  text-align: center;
  background: white;
  padding: 4rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 600px;

  h2 {
    color: #333;
    margin: 1.5rem 0;
    font-size: 1.8rem;
  }

  p {
    color: #666;
    font-size: 1.1rem;
    line-height: 1.6;
  }

  svg {
    color: #999;
    margin-bottom: 1rem;
  }
`;

export default ProjectsPage;