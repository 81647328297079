// ProjectBox.js
import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, description, tag, author }) {
  // Truncate description to 100 characters
  const truncatedDescription = description?.length > 100 
    ? `${description.substring(0, 100)}...` 
    : description;

  return (
    <BoxWrapper>
      <ImageWrapper>
        <img src={img} alt={title} />
      </ImageWrapper>
      <ContentWrapper>
        <Tag>{tag}</Tag>
        <Title>{title}</Title>
        <Description>{truncatedDescription}</Description>
        <Author>By {author}</Author>
      </ContentWrapper>
    </BoxWrapper>
  );
}

const BoxWrapper = styled.div`
  width: 100%;
  height: 450px; // Fixed height for consistency
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 200px; // Fixed height for images
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // This ensures images cover the area properly
    transition: transform 0.3s ease;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Tag = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #667140;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  line-height: 1.4;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 16px;
  flex: 1;
`;

const Author = styled.p`
  font-size: 13px;
  color: #999;
  margin: 0;
`;