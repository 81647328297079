import React from "react";
import styled, { keyframes } from "styled-components";
import LogoImg from "../../assets/img/logo.png";
import {
  Telephone,
  Envelope,
  GeoAlt,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  ArrowUp,
} from "react-bootstrap-icons";

const FooterLinks = () => {
  return (
    <FooterLinksWrapper>
      <FooterSection>
        <img src={LogoImg} style={{ width: 180, borderRadius: 12 }} alt="Logo" />
      </FooterSection>

      <FooterSection>
        <h4>About</h4>
        <ul>
          <li><a href="/about-mission">Mission</a></li>
          <li><a href="/about-history">History</a></li>
          <li><a href="/about-values">Values</a></li>
          <li><a href="/board-of-directors">Board of Directors</a></li>
          <li><a href="/executive-committee">Executive Committee</a></li>
          <li><a href="/staff">Staff</a></li>
        </ul>
      </FooterSection>

      <FooterSection>
        <h4>Projects</h4>
        <ul>
          <li><a href="/projects">Projects</a></li>
          <li><a href="/funded-projects">Funded Projects</a></li>
          <li><a href="/getting-funded">Get Funded</a></li>
          <li><a href="/completed-projects">Completed Projects</a></li>
        </ul>
      </FooterSection>

      <FooterSection>
        <h4>Procurement</h4>
        <ul>
          <li><a href="/procurement-bids">Open Bids</a></li>
          <li><a href="/procurement-awards">Awarded Bids</a></li>
          <li><a href="/procurement-plan">Procurement Plan</a></li>
        </ul>
      </FooterSection>

      <FooterSection>
        <h4>Notices</h4>
        <ul>
          <li><a href="/funding-guidelines">Funding Guidelines</a></li>
          <li><a href="/vacancies">Vacancies</a></li>
          <li><a href="/bursaries">Bursaries</a></li>
        </ul>
      </FooterSection>

      <FooterSection>
        <h4>Media Releases</h4>
        <ul>
          <li><a href="/downloads">Downloads</a></li>
          <li><a href="/blogs">News and Articles</a></li>
          <li><a href="/photo-gallery">Gallery</a></li>
          <li><a href="/video-gallery">Videos</a></li>
        </ul>
      </FooterSection>

      {/* <FooterSection>
        <h4>Help & Support</h4>
        <ul>
          <li><a href="/faqs">FAQs</a></li>
          <li><a href="/disclaimer">Disclaimer Policy</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li> 
        </ul>
      </FooterSection> */}

    </FooterLinksWrapper>
  );
};

const AddressBox = () => {
  return (
    <AddressBoxWrapper>
      <AddressItem>
        <GeoAlt /> Eugene Marais Street, Windhoek
      </AddressItem>
      <AddressItem>
        <Telephone /> (+264) 061 240 100
      </AddressItem>
      <AddressItem>
        <Envelope /> info@gptf.org.na
      </AddressItem>
    </AddressBoxWrapper>
  );
};

export default function Footer() {
  const getCurrentYear = () => new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterLinks />
        <HorizontalLine />
        <BottomSection>
          <AddressContainer>
            <AddressBox />
            <FollowUsContainer>
              <FollowUsBackground>
                <ul className="social-media">
                  <li>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                      <Facebook /> Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                      <Twitter /> X / Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                      <Instagram /> Instagram
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                      <Linkedin /> Linkedin
                    </a>
                  </li>
                </ul>
              </FollowUsBackground>
            </FollowUsContainer>
          </AddressContainer>
        </BottomSection>
        <HorizontalLine />
        <BottomSection>
          <StyleP style={{ marginBottom: '20px' }}>
            © {getCurrentYear()} - <span>Game Products Trust Fund (GPTF) - All Rights Reserved</span>
          </StyleP>
          <StyleP style={{ marginBottom: '20px', marginLeft: '20px' }}>
            Made With ❤️ by <a href="https://loop.com.na" target="_blank" rel="noopener noreferrer">Loop Technologies</a>
          </StyleP>
          <StyleP style={{ marginBottom: '20px', marginLeft: '20px' }}>
            <a href="/disclaimer">Disclaimer</a> | <a href="/faqs">FAQs</a>
          </StyleP>
          <BackToTopButton onClick={scrollToTop} style={{ marginLeft: '20px', marginBottom: '15px' }}>
            <ArrowCircle>
              <ArrowUp size={20} />
            </ArrowCircle>
            Back to top
          </BackToTopButton>
        </BottomSection>

      </FooterContent>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  background-color: #60743c;
  width: 100%;
  padding: 10px 0;
  color: white;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 10px;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 150px;
  margin: 15px;

  @media (max-width: 768px) {
    min-width: 140px; /* Slightly smaller on mobile */
    margin: 10px; /* Reduced margin on mobile */
    
    /* Ensure two columns on mobile */
    flex-basis: calc(50% - 20px);
    
    h4 {
      font-size: 16px; /* Slightly smaller headings on mobile */
    }
    
    ul li {
      font-size: 14px; /* Slightly smaller text on mobile */
    }
  }

  h4 {
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 10px;

  @media (max-width: 768px) {
    justify-content: flex-start; /* Left align on mobile */
    padding: 0 10px; /* Add some padding on mobile */
  }
`;


const AddressContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute space evenly */
  align-items: center; /* Align items vertically in the center */
  width: 100%; /* Full width for the container */
  margin: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items on smaller screens */
    align-items: flex-start; /* Align items to the start */
    gap: 10px; /* Space between stacked items */
  }
`;

const FollowUsContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  margin-left: 20px; 
  flex: 1; 

  @media (max-width: 768px) {
    margin-left: 0; 
    margin-top: 10px;
    width: 100%; 
  }
`;



const FollowUsBackground = styled.div`
  background-color: #a59650; 
  border-radius: 10px;
  padding: 8px;
  width: auto; 

  ul {
    display: flex; 
    gap: 15px; 
    flex-wrap: wrap; 
    margin: 0; 
    padding: 0; 
    list-style: none; 
  }

  @media (max-width: 768px) {
    margin-top: 10px; 
    width: 100%; 
  }
`;

const AddressBoxWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically */
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items on smaller screens */
    align-items: center; /* Center items in column layout */
  }
`;

const AddressItem = styled.p`
  padding-right: 15px;
  color: white;
  font-size: 14px;
  margin: 0; /* Remove margin to maintain consistent height */

  display: flex; /* Use flex to align icon and text */
  align-items: center; /* Center icon and text vertically */

  svg {
    margin-right: 5px; /* Space between icon and text */
  }
`;


const HorizontalLine = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 20px 0;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const StyleP = styled.p`
  color: white;
  font-size: 13px;
  margin: 5px 0;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  /* Add spacing between the separator */
  a + a {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    margin-left: 0 !important; /* Override inline margin on mobile */
    text-align: center;
    width: 100%;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const BackToTopButton = styled.button`
  display: flex;
  align-items: center;
  color: white;
  background: none;
  border: none;
  font-size: 13px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const ArrowCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(96, 116, 60, 0.3);
  margin-right: 8px;
  animation: ${float} 2s ease-in-out infinite;

  svg {
    color: white;
  }
`;
