import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import styled from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import HeaderImage1 from "../../assets/img/wildlife-cheeter.jpg";
import HeaderImage2 from "../../assets/img/about-us-header.avif";
import HeaderImage3 from "../../assets/img/desert-safari.avif";
import QuotesIcon from "../../assets/svg/Quotes";

export default function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate(); 

  const slides = [
    {
      image: HeaderImage1,
      title: "We are the Game Products Trust Fund",
      text: "Join us on our mission to protect wildlife habitats and promote the co-existence of wildlife and human.",
      buttonText: "Learn More",
      navigateTo: "/about-mission",
    },
    // { NB: removed until futher notice
    //   image: HeaderImage2,
    //   title: "Conservation Starts With You",
    //   text: "Your support helps us safeguard the natural beauty of our environment for future generations.",
    //   buttonText: "Get Involved",
    //   navigateTo: "/support-us",
    // },
    {
      image: HeaderImage3,
      title: "Sustainable Practices for a Greener Future",
      text: "Discover how our initiatives are making a difference in the world of conservation.",
      buttonText: "Explore Projects",
      navigateTo: "/projects",
    },
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 9000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  // navigation handler for buttons
  const handleNavigation = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <HeroWrapper>
      <SlideWrapper>
        {slides.map((slide, index) => (
          <Slide
            key={index}
            style={{
              backgroundImage: `url(${slide.image})`,
              display: index === currentSlide ? "block" : "none",
            }}
          >
            <Overlay />
            <Content>
              <h1>{slide.title}</h1>
              <p>{slide.text}</p>
              <Button onClick={() => handleNavigation(slide.navigateTo)}>
                {slide.buttonText}
              </Button>
            </Content>
          </Slide>
        ))}
        <ArrowLeft onClick={handlePrev} />
        <ArrowRight onClick={handleNext} />
      </SlideWrapper>
      <QuoteSection>
        <QuoteIconWrapper>
          <QuotesIcon />
        </QuoteIconWrapper>
        <QuoteText>
          <p>
            <em>
              "Friends, such as we desire, are dreams and fables. Friendship
              demands the ability to do without it."
            </em>
          </p>
          <p className="author">- Ralph Waldo Emerson</p>
        </QuoteText>
      </QuoteSection>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const SlideWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Slide = styled.div`
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  top: 40%;

  h1 {
    font-size: 3rem;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 2rem; /* Reduce font size for mobile */
    }

    @media (max-width: 480px) {
      font-size: 1.5rem; /* Even smaller for very small screens */
    }
  }

  p {
    font-size: 1.2rem;
    margin: 1.5rem 0;

    @media (max-width: 768px) {
      font-size: 1rem; /* Adjust paragraph text for mobile */
      margin: 1rem 0;
    }

    @media (max-width: 480px) {
      font-size: 0.9rem; /* Further reduce on smaller screens */
    }
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #a5c838;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #8bb52f;
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust button text size */
    padding: 8px 18px;  /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Even smaller for small screens */
    padding: 6px 16px;  /* Further reduce padding */
  }
`;

const ArrowLeft = styled(FaArrowLeft)`
  position: absolute;
  top: 60%;
  left: 20px;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 3;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller arrows for mobile */
  }

  @media (max-width: 480px) {
    left: 10px; /* Adjust the arrow position */
    font-size: 1.2rem;
  }
`;

const ArrowRight = styled(FaArrowRight)`
  position: absolute;
  top: 60%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 3;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller arrows for mobile */
  }

  @media (max-width: 480px) {
    right: 10px; /* Adjust the arrow position */
    font-size: 1.2rem;
  }
`;

const QuoteSection = styled.div`
  position: absolute;
  bottom: 50px;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
  z-index: 3;
  color: white;

  @media (max-width: 768px) {
    bottom: 30px; /* Adjust quote section for smaller screens */
    right: 10px;
    padding: 15px;
  }

  @media (max-width: 480px) {
    bottom: 20px; /* Even smaller adjustments */
    right: 5px;
    padding: 10px;
    max-width: 250px;
  }
`;

const QuoteIconWrapper = styled.div`
  position: absolute;
  top: -15px;
  left: -15px;

  @media (max-width: 768px) {
    top: -10px;
    left: -10px;
  }

  @media (max-width: 480px) {
    top: -8px;
    left: -8px;
  }
`;

const QuoteText = styled.div`
  p {
    font-size: 1rem;
    margin: 0;
    em {
      font-style: italic;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem; /* Adjust quote text */
    }

    @media (max-width: 480px) {
      font-size: 0.8rem; /* Further reduce on small screens */
    }
  }

  .author {
    font-size: 0.8rem;
    text-align: right;
    margin-top: 10px;
    color: #a5c838;

    @media (max-width: 768px) {
      font-size: 0.7rem;
    }

    @media (max-width: 480px) {
      font-size: 0.6rem;
    }
  }
`;

