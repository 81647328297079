import React, { useState } from 'react';
import styled from 'styled-components';
import LogoImg from "../../assets/img/logo.png";
import { X, FileQuestion } from 'lucide-react';
import DocumentLoading from 'components/DocumentLoading/DocumentLoading';

const Modal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  const isDocument = typeof content === 'string';

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent 
        isDocument={isDocument}
        onClick={e => e.stopPropagation()}
      >
        <CloseButton onClick={onClose}>
          <X size={20} />
        </CloseButton>
        {React.isValidElement(content) ? (
          content
        ) : (
          <iframe src={content} width="100%" height="100%" style={{ border: 'none' }} />
        )}
      </ModalContent>
    </ModalOverlay>
  );
};


const ProcurementPlanPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const filePath = "/data/ProcurementPlan/gptf.pdf";

  // You can toggle this to test the no documents state
  const hasDocuments = false;

  const annualProcurementPlan = hasDocuments ? {
    title: "Annual Procurement Plan 2024",
    size: "9.4 KB",
    link: filePath
  } : null;

  const archivedDocuments = hasDocuments ? [
    { title: "Annual Procurement Plan 2023", size: "9.4 KB", link: filePath },
    { title: "Annual Procurement Plan 2022", size: "9.4 KB", link: filePath },
    { title: "Annual Procurement Plan 2021", size: "9.4 KB", link: filePath },
    { title: "Annual Procurement Plan 2020", size: "9.4 KB", link: filePath },
  ] : [];

  const handleDownload = (link) => {
    const a = document.createElement('a');
    a.href = link;
    a.download = link.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const NoDocumentsMessage = () => (
    <NoDocumentsWrapper>
      <FileQuestion size={64} color="#9bbb59" />
      <NoDocumentsText>Documents will be uploaded shortly.</NoDocumentsText>
    </NoDocumentsWrapper>
  );
  
  // const handleView = (link) => {
  //   setModalContent(link); // File path should be correct
  //   setModalOpen(true);
  // };

  const handleNotLoadedView = () => {
    setModalContent(<DocumentLoading />);
    setModalOpen(true);
  };

  return (
    <PageWrapper>
      <TitleWrapper>
        <Title>ANNUAL PROCUREMENT PLAN</Title> 
        <TitleLine />
      </TitleWrapper>
      
      <Section>
        {hasDocuments ? (
          <CurrentPlanCard>
            <Logo src={LogoImg} alt="Logo" />
            <Content>
              <DocumentTitle>{annualProcurementPlan.title}</DocumentTitle>
              <DocumentSize>{annualProcurementPlan.size}</DocumentSize>
              <Line />
              <ButtonGroup>
                <ActionButton onClick={() => handleDownload(annualProcurementPlan.link)}>Download</ActionButton>
                <ActionButton onClick={() => handleNotLoadedView()}>View</ActionButton>
              </ButtonGroup>
            </Content>
          </CurrentPlanCard>
        ) : (
          <NoDocumentsMessage />
        )}
      </Section>

      <Section>
        <TitleWrapper>
          <SectionTitle>Archive - Annual Procurement Plan</SectionTitle>
          <TitleLine />
        </TitleWrapper>
        {hasDocuments ? (
          <Container>
            {archivedDocuments.map((doc, index) => (
              <DocumentCard key={index}>
                <Logo src={LogoImg} alt="Logo" />
                <Content>
                  <DocumentTitle>{doc.title}</DocumentTitle>
                  <DocumentSize>{doc.size}</DocumentSize>
                  <Line />
                  <ButtonGroup>
                    <ActionButton onClick={() => handleDownload(doc.link)}>Download</ActionButton>
                    <ActionButton onClick={() => handleNotLoadedView()}>View</ActionButton>
                  </ButtonGroup>
                </Content>
              </DocumentCard>
            ))}
          </Container>
        ) : (
          <NoDocumentsMessage />
        )}
      </Section>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        content={modalContent}
      />
    </PageWrapper>
  );
};


const PageWrapper = styled.div`
  position: relative;
  padding: 20px; /* Adjusted for mobile */
  max-width: 1200px;
  margin: 0 auto;
  top: 70px;

  @media (max-width: 768px) {
    padding: 10px; /* Less padding for smaller screens */
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 20px; /* Adjusted for mobile */
  padding-top: 20px;
  color: #9bbb59;
`;

const Title = styled.h1`
  font-size: 1.75rem; /* Adjusted for mobile */
  font-weight: 600;
  margin: 0;
  padding-top: 5rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller title on mobile */
  }
`;

const TitleLine = styled.div`
  position: absolute;
  margin-top: 3rem;
  top: 20%;
  left: 0;
  width: 15%;
  height: 4px;
  background-color: #9bbb59;
  transform: translateY(-50%);
`;

const Section = styled.div`
  margin-bottom: 40px;
  padding: 40px 0; /* Adjusted for mobile */
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem; /* Adjusted for mobile */
  font-weight: 500;
  margin-bottom: 20px;
  color: #9bbb59;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Smaller section title on mobile */
  }
`;

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }
`;

const DocumentCard = styled.div`
  color: #c4ba91;
  display: flex;
  align-items: center;
  background: #c3b98f21;
  padding: 20px;
  border: 0.5px solid #61743c;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: flex-start; /* Align to start */
    padding: 15px; /* Adjusted padding */
  }
`;

const CurrentPlanCard = styled.div`
  color: #c4ba91;
  display: flex;
  align-items: center;
  background: #c3b98f21;
  padding: 20px;
  border: 0.5px solid #61743c;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%; /* Full width on mobile */
  }
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border: 0.5px solid #61743c;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 40px; /* Smaller logo on mobile */
    height: 40px;
    margin-right: 15px; /* Adjusted margin */
  }
`;

const Content = styled.div`
  flex: 1;
`;

const DocumentTitle = styled.h3`
  font-size: 1.25rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller title on mobile */
  }
`;

const DocumentSize = styled.p`
  font-size: 0.875rem;
  color: #666;
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Smaller size text on mobile */
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 5px; /* Less gap on mobile */
  }
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  font-size: 0.875rem;
  color: #fff;
  background-color: #9bbb59;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #005bb5;
  }

  @media (max-width: 768px) {
    padding: 8px 16px; /* Smaller button padding on mobile */
    font-size: 0.75rem; /* Smaller font size on mobile */
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  position: relative;
  width: auto;
  max-width: 90%;
  max-height: 90vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ${props => props.isDocument ? `
    width: 80%;
    height: 80vh;
    padding: 20px;
  ` : `
    width: auto;
    height: auto;
    padding: 0;
  `}

  @media (max-width: 768px) {
    width: 95%;
    max-height: 80vh;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  z-index: 1001;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #333;
  }
`;

const NoDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #c3b98f21;
  border: 0.5px solid #61743c;
  border-radius: 8px;
  min-height: 200px;
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 20px;
    min-height: 160px;
  }
`;

const NoDocumentsText = styled.p`
  color: #9bbb59;
  font-size: 1.25rem;
  margin-top: 20px;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 16px;
  }
`;

export default ProcurementPlanPage;
