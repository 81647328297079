import Man1 from "../assets/img/team/man-team-1.avif";
import Man2 from "../assets/img/team/man-team-2.avif";
import Man3 from "../assets/img/team/man-team-3.avif";
import Woman1 from "../assets/img/team/woman-team-1.avif";
import Woman2 from "../assets/img/team/woman-team-2.avif";
import Woman3 from "../assets/img/team/woman-team-3.avif";

export const boardOrgChartData = {
    name: "Mr. Colgar Sikopo",
    title: "Chairperson",
    image: Man1,
    children: [
      {
        name: " Ms. Mclesia Mbaisa",
        title: "Vice Chairperson",
        image: Woman1,
        children: [
          {
            name: "Mr. Kandiwapa Amwele",
            title: "Board Member",
            image: Man1,
          },           
          {
            name: "Ms. Doreen Saisai",
            title: "Board Member",
            image: Woman3,
          },   
          {
            name: "Mr. Thomas Muronga",
            title: "Board Member",
            image: Man1,
          },
          {
            name: "Mr. Frans Kamenye",
            title: "Fund Manager",
            image: Man1,
          },                      
        ],
      },   
    ],
  };
