import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getProjects } from "store/actions/cmsActions";
import { FaList, FaThLarge, FaDownload, FaProjectDiagram } from 'react-icons/fa';
import ProjectPageProjectBox from "./ProjectPageProjectBox";

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';
  const [viewMode, setViewMode] = useState('grid');

  console.log('Current projects:', projects); // This will log on every render when projects change


  useEffect(() => {
    dispatch(getProjects(organizationId));
    // console.log(projects)
  }, [dispatch]);

  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  return (
    <PageWrapper>
      <Header>
        <HeaderTitle>Our Awesome Projects</HeaderTitle>
        <HeaderSubtitle>
          Explore our range of conservation and community development projects across Namibia.
          These initiatives showcase our commitment to wildlife protection and sustainable resource management.
        </HeaderSubtitle>
      </Header>
      <ContentWrapper>
        <Container>
          {projects && projects.length > 0 ? (
            <>
              <ControlsContainer>
                <ViewToggle onClick={toggleViewMode}>
                  {viewMode === 'grid' ? <FaList /> : <FaThLarge />}
                  <span>{viewMode === 'grid' ? ' List View' : ' Grid View'}</span>
                </ViewToggle>
              </ControlsContainer>
              
              {viewMode === 'grid' ? (
                <ProjectGrid>
                  {projects.map((project, index) => (
                    <ProjectPageProjectBox
                      key={index}
                      imageUrl={project.imageUrl}
                      title={project.title}
                      description={project.description}
                      cost={project.cost}
                      date={project.date}
                    />
                  ))}
                </ProjectGrid>
              ) : (
                <TableWrapper>
                  <ResponsiveTable>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project, index) => (
                        <tr key={index}>
                          <td data-label="Title">{project.title}</td>
                          <td data-label="Description">
                            {project.description?.length > 100
                              ? `${project.description.substring(0, 100)}...`
                              : project.description}
                          </td>
                          <td data-label="Cost">{project.cost || 'Unknown'}</td>
                          <td data-label="Date">{project.date || 'Unknown date'}</td>
                          <td data-label="Action">
                            <DownloadButton>
                              <FaDownload /> Download
                            </DownloadButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ResponsiveTable>
                </TableWrapper>
              )}
            </>
          ) : (
            <NoProjectsMessage>
              <FaProjectDiagram size={50} />
              <h2>No Projects Available</h2>
              <p>There are currently no projects to display. Please check back later for updates on our initiatives.</p>
            </NoProjectsMessage>
          )}
        </Container>
      </ContentWrapper>
    </PageWrapper>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
`;

const ContentWrapper = styled.div`
  padding: 2rem 0;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;

  th, td {
    padding: 1rem;
    text-align: left;
  }

  th {
    background-color: #8c9c6c;
    color: white;
    font-weight: 500;
    white-space: nowrap;
  }

  tr {
    border-bottom: 1px solid #eee;
  }

  tr:hover {
    background-color: #f8f8f8;
  }

  @media screen and (max-width: 768px) {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid #eee;
    }

    td {
      display: block;
      text-align: right;
      padding: 0.5rem 1rem;
      
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #667140;
      }
      
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

const Header = styled.header`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 7rem;
  padding-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 2rem 0;
  background: ${colors.light};
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const ViewToggle = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const ProjectCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 1rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.25rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const ProjectDescription = styled.p`
  color: ${colors.neutral};
  margin-bottom: 1rem;
  font-size: 0.9rem;
`;

const DownloadButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: ${colors.neutral};
  margin-top: 1rem;
`;

const ProjectTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid ${colors.light};
  }

  th {
    background-color: ${colors.primary};
    color: white;
  }

  tr:nth-child(even) {
    background-color: ${colors.light};
  }
`;

const NoProjectsMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

export default ProjectsPage;