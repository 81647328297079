import React from "react";
import styled from "styled-components";
import ServiceBox from "../Elements/ServiceBox";

const Wrapper = styled.section`
  width: 100%;
`;

const ServiceBoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What We Do</h1>
            <p className="font13">
              At the Game Products Trust Fund, we are driven to fulfill the founding objectives:
            </p>
          </HeaderInfo>
          <ServiceBoxRow>
            <ServiceBox
              icon="money-grant"
              title="Grant Making"
              subtitle="We provide grants to emerging conservancies and wildlife councils to implement and maintain projects approved by our Board, focusing on wildlife conservation, management, and rural development."
            />
            <ServiceBox
              icon="funds"
              title="Fund Allocation"
              subtitle="We allocate funds to conservancies, wildlife councils, protected areas, and approved individuals/organizations to support projects and programs related to wildlife conservation, management, and rural development."
            />
            <ServiceBox
              icon="community"
              title="Community Engagement"
              subtitle="We support initiatives aimed at improving the relationship between people and wildlife, fostering a harmonious coexistence and mutual respect."
            />
            <ServiceBox
              icon="wildlife"
              title="Wildlife Resource Management"
              subtitle="We support efforts to enhance monitoring, management, protection, sustainable use, and development of wildlife resources in rural areas, ensuring their long-term viability and conservation."
            />
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}
