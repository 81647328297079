import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FaList, FaThLarge, FaSearchDollar, FaDownload } from 'react-icons/fa';
import { getVacancies } from "store/actions/cmsActions"; 

const VacanciesPage = () => {
  const dispatch = useDispatch();
  const { vacancys } = useSelector((state) => state.cmsReducer); // Note spelling
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';
  const [viewMode, setViewMode] = useState('grid');

  useEffect(() => {
    dispatch(getVacancies(organizationId));
  }, [dispatch]);
  
  console.log("Vacancies:", vacancys); // Check if it updates

  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  // Helper function to format date
  const formatDate = (timestamp) => {
    return timestamp 
      ? new Date(timestamp * 1000).toLocaleDateString('en-US', {
          year: 'numeric', 
          month: 'long', 
          day: 'numeric'
        }) 
      : 'Not specified';
  };

  return (
    <>
      <Header>
        <HeaderTitle>Job Vacancies</HeaderTitle>
        <HeaderSubtitle>
          Explore career opportunities with our organization. Check back regularly for new openings.
        </HeaderSubtitle>
      </Header>
      <ContentWrapper>
        <Container>
          {vacancys && vacancys.length > 0 ? (
            <>
              <ControlsContainer>
                <ViewToggle onClick={toggleViewMode}>
                  {viewMode === 'grid' ? <FaList /> : <FaThLarge />}
                  <span>{viewMode === 'grid' ? ' List View' : ' Grid View'}</span>
                </ViewToggle>
              </ControlsContainer>
              
              {viewMode === 'grid' ? (
                <VacancyGrid>
                  {vacancys.map((vacancy) => (
                    <VacancyCard key={vacancy.id}>
                      <VacancyContent>
                        <VacancyTitle>{vacancy.title}</VacancyTitle>
                        <VacancyDescription>
                          {vacancy.description?.length > 150
                            ? `${vacancy.description.substring(0, 150)}...`
                            : vacancy.description}
                        </VacancyDescription>
                        <VacancyInfo>
                          <span>Posted: {formatDate(vacancy.datePosted)}</span>
                          <DetailsButton>View Details</DetailsButton>
                        </VacancyInfo>
                      </VacancyContent>
                    </VacancyCard>
                  ))}
                </VacancyGrid>
              ) : (
                <TableWrapper>
                  <ResponsiveTable>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Date Posted</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vacancys.map((vacancy) => (
                        <tr key={vacancy.id}>
                          <td data-label="Title">{vacancy.title}</td>
                          <td data-label="Description">
                            {vacancy.description?.length > 100
                              ? `${vacancy.description.substring(0, 100)}...`
                              : vacancy.description}
                          </td>
                          <td data-label="Date Posted">{formatDate(vacancy.datePosted)}</td>
                          <td data-label="Action">
                            <DownloadButton>
                              <FaDownload /> Apply
                            </DownloadButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ResponsiveTable>
                </TableWrapper>
              )}
            </>
          ) : (
            <NoVacanciesMessage>
              <FaSearchDollar size={50} />
              <h2>No Current Vacancies</h2>
              <p>We don't have any open positions at the moment. Please check back later for future opportunities.</p>
            </NoVacanciesMessage>
          )}
        </Container>
      </ContentWrapper>
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const ContentWrapper = styled.div`
  padding: 2rem 0;
`;

const Header = styled.header`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 7rem;
  padding-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const ViewToggle = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const VacancyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const VacancyCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const VacancyContent = styled.div`
  padding: 1rem;
`;

const VacancyTitle = styled.h3`
  font-size: 1.25rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const VacancyDescription = styled.p`
  color: ${colors.neutral};
  margin-bottom: 1rem;
  font-size: 0.9rem;
`;

const VacancyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: ${colors.neutral};
  margin-top: 1rem;
`;

const DetailsButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;

  th, td {
    padding: 1rem;
    text-align: left;
  }

  th {
    background-color: #8c9c6c;
    color: white;
    font-weight: 500;
    white-space: nowrap;
  }

  tr {
    border-bottom: 1px solid #eee;
  }

  tr:hover {
    background-color: #f8f8f8;
  }

  @media screen and (max-width: 768px) {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid #eee;
    }

    td {
      display: block;
      text-align: right;
      padding: 0.5rem 1rem;
      
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #667140;
      }
      
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

const DownloadButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const NoVacanciesMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

export default VacanciesPage;