import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaLightbulb, FaExclamationTriangle, FaComments } from 'react-icons/fa';
import ContactCard from 'components/ContactCard/ContactCard';
import CrimeReportForm from 'components/Forms/CrimeReportForm';
import InputForm from 'components/Forms/InputForm';
import FeedbackForm from 'components/Forms/FeedbackForm';
import axios from 'axios';

const ContactUsPage = () => {
  const [activeForm, setActiveForm] = useState(null);

  const toggleForm = (formName) => {
    setActiveForm(activeForm === formName ? null : formName);
  };

  const handleFeedbackSubmit = async (formData) => {
    try {
      const response = await axios.post('https://qualis-email-server.vercel.app/api/custom-mails/gptf/gptf-feedback/', formData);
      console.log(response.data);
      alert('Your message has been sent successfully!');
      setActiveForm(null); // Close the form after submission
    } catch (error) { 
      console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again later.');
    }
  };

  const handleInputSubmit = async (formData) => {
    try {
      const response = await axios.post('https://qualis-email-server.vercel.app/api/custom-mails/gptf/gptf-input/', formData);
      console.log(response.data);
      alert('Your message has been sent successfully!');
      setActiveForm(null); // Close the form after submission
    } catch (error) {
      console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again later.');
    }
  };

  const handleReportSubmit = async (formData) => {
    try {
      const response = await axios.post('https://qualis-email-server.vercel.app/api/custom-mails/gptf/gptf-report/', formData);
      console.log(response.data);
      alert('Your message has been sent successfully!');
      setActiveForm(null); // Close the form after submission 
    } catch (error) { 
      console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again later.');
    }
  };

  return (
    <PageWrapper>
      <Header>
        <HeaderTitle>Contact Us</HeaderTitle>
        <HeaderSubtitle>We're Here to Assist and Listen</HeaderSubtitle>
      </Header>
      
      <MapSection>
        <MapContainer>
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d921.1474776191941!2d17.08627226963516!3d-22.557029760211005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDMzJzI1LjMiUyAxN8KwMDUnMTIuOSJF!5e0!3m2!1sen!2sna!4v1724143477028!5m2!1sen!2sna"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </MapContainer>
      </MapSection> 

      <ContentWrapper>
        <ContactSection>
          <ContactCard />
          <InfoSection>
            <h2>Why Reach Out?</h2>
            <p>At the Game Products Trust Fund, we are committed to serving the Namibian Public and thus are at all times ready to offer responses to any queries that you might have.</p>
            <h3>What You Can Expect:</h3>
            <ExpectationsList>
              <li>Prompt Response Times</li>
              <li>Expert Guidance and Knowledgeable Staff</li>
              <li>Transparent and Open Communication</li>
            </ExpectationsList>
          </InfoSection>
        </ContactSection>
        <FeedbackSection>
          <FeedbackButton onClick={() => toggleForm('input')}>
            <FaLightbulb />
            <span>Share Your Ideas</span>
          </FeedbackButton>
          <FeedbackButton onClick={() => toggleForm('crime')}>
            <FaExclamationTriangle />
            <span>Report an Issue</span>
          </FeedbackButton>
          <FeedbackButton onClick={() => toggleForm('feedback')}>
            <FaComments />
            <span>Provide Feedback</span>
          </FeedbackButton>
        </FeedbackSection>
      </ContentWrapper>

      {activeForm === 'input' && <InputForm onClose={() => setActiveForm(null)} />}
      {activeForm === 'crime' && <CrimeReportForm onClose={() => setActiveForm(null)} />}
      {activeForm === 'feedback' && <FeedbackForm onClose={() => setActiveForm(null)} />}

    </PageWrapper>
  );
};


const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PageWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  background-color: ${colors.light};
  color: ${colors.neutral};
  min-height: 100vh;
  animation: ${fadeIn} 0.5s ease-out;
  padding-bottom: 3rem;
`;

const Header = styled.header`
  background-color: ${colors.primary};
  margin-top: 80px;
  color: white;
  padding-top: 5rem;
  padding-bottom: 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    margin-top: 7px;
    left: 0;
    right: 0;
    height: 100px;
    background: ${colors.light};
    transform: skewY(-3deg);
  }
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  animation: ${slideIn} 0.5s ease-out;
`;

const HeaderSubtitle = styled.p`
  font-size: 1.2rem;
  opacity: 0.8;
  animation: ${slideIn} 0.5s ease-out 0.2s both;
`;

const MapSection = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: 2rem;
  animation: ${slideIn} 0.5s ease-out 0.4s both;
`;

const MapContainer = styled.div`
  height: 100%;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ContactSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  animation: ${slideIn} 0.5s ease-out 0.6s both;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoSection = styled.div`
  width: 45%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  
  h2, h3 {
    color: ${colors.tertiary};
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const ExpectationsList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0.5rem;
    
    &::before {
      content: '✓';
      position: absolute;
      left: 0;
      color: ${colors.primary};
      font-weight: bold;
    }
  }
`;

const FeedbackSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 2rem;
  animation: ${slideIn} 0.5s ease-out 0.8s both;
`;

const FeedbackButton = styled.button`
  flex: 1;
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
  }

  &:hover {
    background-color: ${colors.tertiary};
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &:active {
    animation: ${pulse} 0.3s ease-in-out;
  }
`;

export default ContactUsPage;