


   // Articles
   export const FETCH_ARTICLES_REQUEST = "FETCH_ARTICLES_REQUEST"
   export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS"
   export const FETCH_ARTICLES_FAILURE = "FETCH_ARTICLES_FAILURE"

  // Categories
  export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST"
  export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS"
  export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE"

  // Documents
  export const FETCH_DOCUMENTS_REQUEST = "FETCH_DOCUMENTS_REQUEST"
  export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS"
  export const FETCH_DOCUMENTS_FAILURE = "FETCH_DOCUMENTS_FAILURE"

  // Menus
  export const  FETCH_MENUS_REQUEST = "FETCH_MENUS_REQUEST"
  export const FETCH_MENUS_SUCCESS = "FETCH_MENUS_SUCCESS"
  export const  FETCH_MENUS_FAILURE = "FETCH_MENUS_FAILURE"

  // Pages
  export const  FETCH_PAGES_REQUEST = "FETCH_PAGES_REQUEST"
  export const FETCH_PAGES_SUCCESS = "FETCH_PAGES_SUCCESS"
  export const FETCH_PAGES_FAILURE = "FETCH_PAGES_FAILURE"

  // Storage
  export const FETCH_STORAGE_REQUEST = "FETCH_STORAGE_REQUEST"
  export const FETCH_STORAGE_SUCCESS = "FETCH_STORAGE_SUCCESS"
  export const  FETCH_STORAGE_FAILURE = "FETCH_STORAGE_FAILURE"

  // Projects
  export const  FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST"
  export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS"
  export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE"

  // Tenders
  export const FETCH_TENDERS_REQUEST = "FETCH_TENDERS_REQUEST"
  export const  FETCH_TENDERS_SUCCESS = "FETCH_TENDERS_SUCCESS"
  export const FETCH_TENDERS_FAILURE = "FETCH_TENDERS_FAILURE"

  // Vacancys
  export const FETCH_VACANCYS_REQUEST = "FETCH_VACANCYS_REQUEST"
  export const FETCH_VACANCYS_SUCCESS = "FETCH_VACANCYS_SUCCESS"
  export const FETCH_VACANCYS_FAILURE = "FETCH_VACANCYS_FAILURE"

  export const vacancysSuccess = (payload) => ({
   type: FETCH_VACANCYS_SUCCESS,
   payload
 });

  // CRUD
  export const ON_CMS_ELEMENT_KEY_UPDATE = "ON_CMS_ELEMENT_KEY_UPDATE"
  export const  ON_CMS_ACTIVE_ELEMENT = "ON_CMS_ACTIVE_ELEMENT"
  export const  ON_CMS_ACTIVE_ELEMENT_CLEAR = "ON_CMS_ACTIVE_ELEMENT_CLEAR"

  // System events
  export const  ON_NOTIFICATION = "ON_NOTIFICATION"


 