import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faHandHoldingUsd, faUsers, faPaw } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const iconMap = {
  "money-grant": faMoneyBill,
  "funds": faHandHoldingUsd,
  "community": faUsers,
  "wildlife": faPaw,
};

const ServiceBoxWrapper = styled.div`
  text-align: center;
  padding: 20px;
  flex: 1;
  max-width: 22%; /* Adjust to fit four items evenly */
  
  @media (max-width: 860px) {
    max-width: 100%; /* Stack items on smaller screens */
    margin-bottom: 20px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: #60743c;
  font-size: 2.5rem;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  color: #60743c;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 10px 0;
`;

const Subtitle = styled.p`
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
`;

const ServiceBox = ({ icon, title, subtitle }) => (
  <ServiceBoxWrapper>
    <Icon icon={iconMap[icon]} />
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </ServiceBoxWrapper>
);

export default ServiceBox;
