import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ClosingDatesSection = () => {
  const [submissionDeadlines, setSubmissionDeadlines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeadlines = async () => {
      try {
        setIsLoading(true);
        // Simulate API call - replace with your actual API call
        // const response = await dispatch(getProjects(organizationId));
        // setSubmissionDeadlines(response.data);
        
        // Temporary mock data
        setSubmissionDeadlines([
        //   { 
        //     id: 1,
        //     title: "Project 1 Example",
        //     deadline: "March 31, 2025",
        //     status: "Open",
        //     description: "First quarter funding applications"
        //   },
        //   {
        //     id: 2,
        //     title: "Project 2 Example",
        //     deadline: "June 30, 2025",
        //     status: "Upcoming",
        //     description: "Second quarter funding applications"
        //   }
        ]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching deadlines:", error);
        setError("Failed to load submission deadlines");
        setIsLoading(false);
      }
    };

    fetchDeadlines();
  }, []);

  if (isLoading) {
    return <LoadingContainer>
      <LoadingSpinner />
      <LoadingText>Loading submission deadlines...</LoadingText>
    </LoadingContainer>;
  }

  if (error) {
    return <ErrorContainer>
      <ErrorIcon>⚠️</ErrorIcon>
      <ErrorMessage>{error}</ErrorMessage>
      <RetryButton onClick={() => window.location.reload()}>
        Retry
      </RetryButton>
    </ErrorContainer>;
  }

  return (
    <Container>
      <Section>
        <SectionTitle>Current Status</SectionTitle>
        <StatusMessage>
          The current funding round has been closed. Please keep up with our information platforms 
          for the next round of funding.
        </StatusMessage>
      </Section>

      <Section>
        <SectionTitle>Upcoming Submission Deadlines</SectionTitle>
        
        {submissionDeadlines.length > 0 ? (
          <DeadlinesList>
            {submissionDeadlines.map((deadline) => (
              <DeadlineCard key={deadline.id}>
                <DeadlineHeader>
                  <DeadlineTitle>{deadline.title}</DeadlineTitle>
                  <StatusBadge status={deadline.status}>
                    {deadline.status}
                  </StatusBadge>
                </DeadlineHeader>
                <DeadlineInfo>
                  <DeadlineDate>
                    <Label>Deadline:</Label> {deadline.deadline}
                  </DeadlineDate>
                  <DeadlineDescription>
                    {deadline.description}
                  </DeadlineDescription>
                </DeadlineInfo>
              </DeadlineCard>
            ))}
          </DeadlinesList>
        ) : (
          <NoDataContainer>
            <NoDataIcon>📅</NoDataIcon>
            <NoDataTitle>No Upcoming Deadlines</NoDataTitle>
            <NoDataMessage>
              There are currently no submission deadlines scheduled. 
              Please check back later for updates on future funding rounds.
            </NoDataMessage>
          </NoDataContainer>
        )}
      </Section>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  padding: 2rem;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h3`
  color: #60743c;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const StatusMessage = styled.p`
  line-height: 1.6;
  color: #666;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  border-left: 4px solid #60743c;
`;

const DeadlinesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DeadlineCard = styled.div`
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #60743c;
  }
`;

const DeadlineHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const DeadlineTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const StatusBadge = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${props => props.status === 'Open' ? '#e6f4ea' : '#fff3e0'};
  color: ${props => props.status === 'Open' ? '#1e7e34' : '#e65100'};
`;

const DeadlineInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DeadlineDate = styled.div`
  font-size: 0.875rem;
  color: #666;
`;

const Label = styled.span`
  font-weight: 600;
  color: #444;
`;

const DeadlineDescription = styled.p`
  font-size: 0.875rem;
  color: #666;
  line-height: 1.6;
`;

// Loading Components
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #60743c;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingText = styled.p`
  color: #666;
  font-size: 1rem;
`;

// Error Components
const ErrorContainer = styled.div`
  text-align: center;
  padding: 3rem;
`;

const ErrorIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  margin-bottom: 1rem;
`;

const RetryButton = styled.button`
  background: #60743c;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #4a5a2f;
  }
`;

// No Data Components
const NoDataContainer = styled.div`
  text-align: center;
  padding: 3rem;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px dashed #dee2e6;
`;

const NoDataIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const NoDataTitle = styled.h4`
  color: #60743c;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const NoDataMessage = styled.p`
  color: #666;
  line-height: 1.6;
`;

export default ClosingDatesSection;